import { Container, SimpleGrid, Stack } from "@mantine/core";
import { useState } from "react";
import PriceCard from "../cards/priceCard";
import { certificates } from "./certificateCards";
import { useCurrentCountry } from "../../../hooks/country";
import { useGetAllServices } from "../../../hooks/user/user.services";

function Services() {
  const [isCertificateOpen] = useState(true);
  const { data: servicesRes } = useGetAllServices();
  const { countryData } = useCurrentCountry();

  const updatedCertificates = () => {
    return servicesRes?.data.map((service: any) => {
      // Find matching certificate based on ServiceCode
      const certsData = certificates.find((certificate) => {
        return service.serviceCode === certificate.ServiceCode;
      });

      // If certsData is found, extend service with additional fields, otherwise return service as is
      if (certsData) {
        return {
          ...service,
          description: certsData?.Description,
          duration: certsData?.Duration,
          icon: certsData?.icon,
          link: certsData?.Link,
          label: certsData?.label,
        };
      }

      // Return the original service object if no match is found
      return service;
    });
  };

  const servicesUpdated = updatedCertificates();

  const certificatesServices = servicesUpdated?.filter(
    (service: any) => service.serviceCategory === "CERTIFICATE"
  );

  return (
    <Container size={"lg"}>
      <Stack align="center">
        <SimpleGrid
          cols={{ base: 1, sm: 2, md: 3 }}
          spacing={"xl"}
          mt={50}
          mb={32}
        >
          {isCertificateOpen &&
            certificatesServices?.map((certificate: any) => {
              return (
                <PriceCard
                  key={certificate.id}
                  description={certificate.description}
                  icon={certificate.icon}
                  serviceType={certificate.label}
                  price={{
                    amount: certificate.dollarAmount,
                    currency: "$",
                  }}
                  to={certificate.link}
                />
              );
            })}
          {/* // : documents.map((certificate, index) => (
              //     <PriceCard
              //       key={index}
              //       actionTexts={[
              //         certificate.Duration,
              //         certificate.Description,
              //       ]}
              //       imgSrc={certificate.Image}
              //       serviceType={certificate.ServiceName}
              //       price={{ currency: "N", amount: certificate.Price }}
              //       to={certificate.Link}
              //     />
              //   ))} */}
        </SimpleGrid>
      </Stack>
    </Container>
  );
}

export default Services;
