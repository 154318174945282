import { Button, Group, Table, Text } from "@mantine/core";
import { Link } from "react-router-dom";

interface ReportTemplateProps {
  watermarkText: string;
  reportStatus: string;
  reportMessage: string;
  reportUrl: string;
}

export const ReportTemplate = ({
  reportMessage,
  reportStatus,
  watermarkText,
  reportUrl,
}: ReportTemplateProps) => {
  return (
    <div style={{ position: "relative", marginTop: "20px" }}>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 0,
          pointerEvents: "none",
          color: "rgba(0, 0, 0, 0.1)", // Light gray color for watermark
          fontSize: "3rem",
          fontWeight: "bold",
          textTransform: "uppercase",
        }}
      >
        {watermarkText}
      </div>

      <Table withRowBorders={true} withTableBorder={true}>
        <Table.Thead bg={"orange"}>
          <Table.Tr>
            <Group justify="center" align="center">
              <Table.Th fw={"bold"}>Verification Report</Table.Th>
            </Group>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          <Group justify="center" align="self-start" dir="column">
            <Table.Tr h={"30vh"}>
              <Table.Td>
                <Text fz="xl" fw="bold">
                  {reportStatus}
                </Text>
                <Text>{reportMessage}</Text>
              </Table.Td>
            </Table.Tr>
          </Group>
        </Table.Tbody>
        <Table.Caption>
          <Link to={reportUrl} target="_blank">
            <Button component="a">Download report</Button>
          </Link>
        </Table.Caption>
      </Table>
    </div>
  );
};
