import {
  Card,
  SimpleGrid,
  Text,
  Group,
  Button,
  Avatar,
  Stack,
  Divider,
  Modal,
  rem,
  Box,
} from "@mantine/core";
import { useState } from "react";
import UserLayout from "../../../../layouts/userLayout";
import RequestDataLayout from "../service/requestDataLayout";
import { PdfIcon } from "../../../../assets/icons/file";
import { getNameInitials } from "../../../shared/reusableFunctions";
import { useCurrentWorkspace } from "../../../../../hooks/user/user.workspace.hook";
import { useGetRequestDetails } from "../../../../../hooks/requests/user.requests.hook";
import { useParams } from "react-router-dom";
import { ServiceCategory } from "../../../../../enums/enums";
import CardLoader from "../../../shared/skeletonLoader/cardLoader";
import { CertificateThreeMenu } from "../../../../control/menu/certificateThreeDots";
import { useDisclosure } from "@mantine/hooks";
import { KpaliLogo } from "../../../../assets/icons";
import { ReportTemplate } from "../../../../../connected/modal/reportTemplate";

function RequestDetails() {
  const { workspaceId } = useCurrentWorkspace();
  const { requestId } = useParams<{ requestId: string }>();
  const {
    data: requestData,
    isLoading: isRequestLoading,
    refetch,
  } = useGetRequestDetails(workspaceId!, requestId!);
  const data =
    requestData?.data?.serviceCategory === ServiceCategory.CERTIFICATE
      ? requestData?.data?.certificates
      : requestData?.data?.documents;

  return (
    <UserLayout title="Request Details">
      <SimpleGrid
        cols={{
          xs: 1,
          sm: 2,
          md: 2,
          lg: 2,
          xl: 3,
        }}
        spacing={{ base: 10, md: "xs", lg: "xl" }}
      >
        {!isRequestLoading && (
          <RequestInfo
            records={data}
            workspaceId={workspaceId!}
            refetch={refetch}
          />
        )}
        {isRequestLoading &&
          Array.from({ length: 12 }).map((_, index) => (
            <CardLoader key={index} />
          ))}
      </SimpleGrid>
      {/* 
      <Modal
        size={"xl"}
        opened={reportOpened}
        onClose={closeReport}
        title={<KpaliLogo style={{ width: rem(100) }} />}
      >
        <ReportTemplate
          watermarkText="Verify by kpali.ng"
          reportMessage="The institution reserves the right to make any comments on the certificate. The attention of the bearer is needed at the Institution immediately."
          reportStatus="Further information required"
          reportUrl={}
        />
      </Modal> */}
    </UserLayout>
  );
}

export const RequestInfo = ({
  records,
  workspaceId,
  refetch,
  isAdmin,
}: {
  isAdmin?: boolean;
  records: any;
  workspaceId?: string;
  refetch?: () => void;
}) => {
  const [hovered, setHovered] = useState<null | number>(null);
  const [reportOpened, { open: openReport, close: closeReport }] =
    useDisclosure(false);

  return (
    <>
      {records?.map((data: any, index: number) => {
        let certificateType = data?.certificateType || data?.documentType;
        certificateType =
          certificateType?.charAt(0).toUpperCase() +
          certificateType?.slice(1).toLowerCase();
        return (
          <Box w={"100%"} key={index}>
            <Card
              onMouseEnter={() => setHovered(index)}
              onMouseLeave={() => setHovered(null)}
              // key={index}
              style={{
                cursor: "default",
                border: hovered === index ? "0.1px solid #E86217" : "none",
              }}
              shadow="sm"
              padding="xl"
            >
              <Card.Section p={"xs"}>
                <Group justify="space-between">
                  <Group gap={"0.5em"}>
                    <Avatar
                      alt={data.applicantName}
                      radius="xl"
                      color="#E86217"
                    >
                      {getNameInitials({
                        name: data.applicantName,
                        maxInitials: 2,
                      })}
                    </Avatar>

                    <Stack gap={0}>
                      <Text size="sm" fw={600} mt={0} mb={0}>
                        {data?.applicantName}
                      </Text>
                      <Text size="sm" fw={400} mt={0} mb={0}>
                        {certificateType}
                      </Text>
                    </Stack>
                  </Group>
                  {isAdmin && (
                    <Group pos="relative">
                      <CertificateThreeMenu
                        record={data}
                        workspaceId={workspaceId}
                        refetch={refetch}
                      />
                    </Group>
                  )}
                </Group>
                <Divider color={"orange"} w={"100%"} size={1} mt={"xs"} />
              </Card.Section>

              <Stack gap={"0.5em"}>
                {data?.status && (
                  <RequestDataLayout label="Status" data={data.status} />
                )}
                {data?.applicantName && (
                  <RequestDataLayout
                    label="Applicant Name"
                    data={data.applicantName}
                  />
                )}
                {data?.subjectName && (
                  <RequestDataLayout
                    label="Subject Name"
                    data={data.subjectName}
                  />
                )}
                {data?.university && (
                  <RequestDataLayout
                    label="University"
                    data={data.university}
                  />
                )}
                {data?.matricNo && (
                  <RequestDataLayout
                    label="Matriculation No"
                    data={data.matricNo}
                  />
                )}
                {data?.registrationNo && (
                  <RequestDataLayout
                    label="Registration No"
                    data={data.registrationNo}
                  />
                )}
                {/* {"Might need to change to certificateName"} */}
                {data?.documentName && (
                  <RequestDataLayout
                    label="Certificate Name"
                    data={data.documentName}
                  />
                )}
                {data?.professionalBody && (
                  <RequestDataLayout
                    label="Professional Body"
                    data={data.professionalBody}
                  />
                )}
                {data?.licenseNo && (
                  <RequestDataLayout label="License No" data={data.licenseNo} />
                )}
                {data?.serviceState && (
                  <RequestDataLayout
                    label="Service State"
                    data={data.serviceState}
                  />
                )}
                {data?.serviceYear && (
                  <RequestDataLayout
                    label="Service Year"
                    data={data.serviceYear}
                  />
                )}
                {data?.certificateNo && (
                  <RequestDataLayout
                    label="Certificate No"
                    data={data.certificateNo}
                  />
                )}
                {data?.nyscNo && (
                  <RequestDataLayout
                    label="Identification No"
                    data={data.nyscNo}
                  />
                )}
                {data?.verificationReason && (
                  <RequestDataLayout
                    label="Verification Reason"
                    data={data.verificationReason}
                  />
                )}
              </Stack>
              <Card.Section p={"xs"}>
                <Divider color={"orange"} w={"100%"} size={0.5} mb={"xs"} />
                <Group justify="space-betwen" grow gap={"xl"}>
                  {data?.certificateUrl && (
                    <Button
                      fz={"sm"}
                      variant="light"
                      component="a"
                      target="_blank"
                      href={data.certificateUrl}
                      rightSection={<PdfIcon size={10} />}
                    >
                      View Certificate
                    </Button>
                  )}
                  {data && data?.status === "VERIFIED" && (
                    <Button variant="light" fz={"sm"} onClick={openReport}>
                      View Report
                    </Button>
                  )}
                </Group>
              </Card.Section>
            </Card>
            <Modal
              size={"xl"}
              opened={reportOpened}
              onClose={closeReport}
              title={<KpaliLogo style={{ width: rem(100) }} />}
            >
              {data && data?.reportStatus === "INCONCLUSIVE" && (
                <ReportTemplate
                  watermarkText="Verify by kpali.ng"
                  reportMessage="The institution reserves the right to make any comments on the certificate. The attention of the bearer is needed at the Institution immediately."
                  reportStatus="Further information required"
                  reportUrl={data.reportUrl}
                />
              )}
              {data && data?.reportStatus === "FAKE" && (
                <ReportTemplate
                  watermarkText="Verify by kpali.ng"
                  reportMessage=" This certificate or document provided is fake. The
                      institution did not award the certificate to the bearer"
                  reportStatus="Fake Report"
                  reportUrl={data.reportUrl}
                />
              )}

              {data && data?.reportStatus === "AUTHENTIC" && (
                <ReportTemplate
                  watermarkText="Verified by kpali.ng"
                  reportMessage="This certificate provided has been confirmed as authentic
                      and awarded by the institution to the bearer"
                  reportStatus="Authentic Report"
                  reportUrl={data.reportUrl}
                />
              )}
            </Modal>
          </Box>
        );
      })}
    </>
  );
};

export default RequestDetails;
