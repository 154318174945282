import { Box, Text, SimpleGrid, Modal, rem } from "@mantine/core";
import PropTypes from "prop-types";
import { ServiceCategory } from "../../../../../../enums/enums";
import { RequestInfo } from "../../../user/requests/requestDetails";
import CardLoader from "../../../../shared/skeletonLoader/cardLoader";

function RequestDetails({
  requestDetails,
  isLoading,
  workspaceId,
  refetch,
  isAdmin,
}: {
  requestDetails: any;
  isAdmin?: boolean;
  isLoading: boolean;
  workspaceId: string;
  refetch?: () => void;
}) {
  const data =
    requestDetails?.serviceCategory === ServiceCategory.CERTIFICATE
      ? requestDetails?.certificates
      : requestDetails?.documents;

  return (
    <RequestComponent requestId={data?.id}>
      <Box
        maw={"500px"}
        h={"100%"}
        style={{
          border: "1.5px solid #E8E8E8",
        }}
      ></Box>
      <SimpleGrid
        cols={{
          xs: 1,
          sm: 2,
          md: 1,
          lg: 2,
          xl: 3,
        }}
        spacing={{ base: 10, md: "xs", lg: "xl" }}
      >
        {!isLoading && (
          <RequestInfo
            records={data}
            workspaceId={workspaceId}
            refetch={refetch}
            isAdmin={isAdmin}
          />
        )}
        {isLoading &&
          Array.from({ length: 12 }).map((_, index) => (
            <CardLoader key={index} />
          ))}
      </SimpleGrid>
    </RequestComponent>
  );
}

export default RequestDetails;

export function RequestComponent({
  requestId,
  children,
}: PropTypes.InferProps<typeof RequestComponent.propTypes>) {
  return (
    <Box
      bg={"light-grey.4"}
      p={"2.5em"}
      style={{
        boxShadow: " 0px 4px 16px 0px #0000000D",
        border: "1.30px solid #E8E8E8",
        borderRadius: "2em",
      }}
    >
      <Text fw={700} fz={"20px"} c={"light-grey.8"} mb={"1em"}>
        ReqID:{" "}
        <Text fw={700} fz={"20px"} c={"blue.0"} component="span">
          {requestId}
        </Text>
      </Text>
      {children}
    </Box>
  );
}

RequestComponent.propTypes = {
  requestId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
